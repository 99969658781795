import { useTranslation } from "react-i18next";

const ManagerDeleteModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold mb-4 text-black dark:text-white">
          {t("managers.managerModal.managerModalConfirmTitle")}
        </h2>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          {t("managers.managerModal.managerModalConfirmText")}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:text-white rounded-md"
          >
            {t("managers.managerModal.managerModalConfirmCencel")}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-md"
          >
            {t("managers.managerModal.managerModalConfirmConfirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManagerDeleteModal;
