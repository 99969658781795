import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const ManagerTable = ({ managers, onSalesEditClick, onEditManager }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-[40vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              t("managers.managersTable.managerTableId"),
              t("managers.managersTable.managerTableName"),
              t("managers.managersTable.managerTablePhone"),
              t("managers.managersTable.managerTableSales"),
              t("managers.managersTable.managerTableContracts"),
              t("managers.managersTable.managerTableActions"),
            ].map((heading) => (
              <th
                key={heading}
                className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {managers.map((manager, index) => (
            <tr key={manager.id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="py-2 capitalize text-gray-800 dark:text-white">
                {manager.name}
              </td>
              <td className="px-2 py-2 text-gray-800 dark:text-white">
                {manager.phone}
              </td>
              <td className="px-8 py-2 text-gray-800 dark:text-white">
                {manager.totalItemsSold}
              </td>
              <td className="px-8 py-2 text-start">
                <button
                  onClick={() => onSalesEditClick(manager)}
                  className="text-green-600 hover:text-green-800 dark:text-green-400"
                >
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
              <td className="px-6 py-2 text-start">
                <button
                  onClick={() => onEditManager(manager)}
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400"
                >
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerTable;
