import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { CustomerModal, CustomerTable, Search } from "../components";
import Loader from "../ui/Loader";
import { showToast } from "./Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const BASE_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";

export default function Customers({ isDarkMode }) {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const token = process.env.REACT_APP_ACCESS_TOKEN;

  const { t } = useTranslation();

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/customers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setCustomers(data || []);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    const filtered = customers.filter((customer) => {
      const matchesSearch = Object.values(customer)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesFilter =
        selectedItem === "" || customer.name === selectedItem;
      return matchesSearch && matchesFilter;
    });
    setFilteredCustomers(filtered);
  }, [customers, searchQuery, selectedItem]);

  const handleSave = (updatedCustomer) => {
    setCustomers((prev) => {
      const index = prev.findIndex((c) => c.id === updatedCustomer.id);
      if (index >= 0) {
        const newCustomers = [...prev];
        newCustomers[index] = updatedCustomer;
        showToast("info", "Customer updated successfully!");
        return newCustomers;
      }
      showToast("success", "Customer added successfully!");
      return [...prev, updatedCustomer];
    });
    setIsCustomerModalOpen(false);
  };

  const handleRemoveCustomer = async (id) => {
    try {
      await fetch(`${BASE_URL}/customers/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json",
        },
      });
      setCustomers((prev) => prev.filter((customer) => customer.id !== id));
      showToast("warning", "Customer deleted successfully!");
    } catch (error) {
      showToast("error", "Error deleting customer!");
      console.error("Error deleting customer:", error);
    } finally {
      fetchCustomers();
      setIsCustomerModalOpen(false);
    }
  };

  return (
    <div className="min-h-screen ml-10 min-w-full pt-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("customers.customerTitle")}
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4 mb-6">
        <div className="flex justify-between items-center gap-4 mb-4">
          <h2 className="text-xl font-semibold text-black dark:text-white">
            {t("customers.customersTableName")}
          </h2>
          <Search
            isDarkMode={isDarkMode}
            onSearch={(query) => setSearchQuery(query)}
          />
          <button
            onClick={() => {
              setIsCustomerModalOpen(true);
              setSelectedCustomer(null);
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-1"
          >
            <PlusIcon className="w-5 h-5" />
            {t("customers.customerAddButton")}
          </button>
        </div>
        <ToastContainer />
        {isLoading ? (
          <Loader />
        ) : filteredCustomers.length > 0 ? (
          <CustomerTable
            customers={filteredCustomers}
            onEditCustomer={(customer) => {
              setSelectedCustomer(customer);
              setIsCustomerModalOpen(true);
            }}
            onRemoveCustomer={handleRemoveCustomer}
          />
        ) : (
          <p className="text-gray-700 dark:text-gray-300">
            {t("customers.customerNoData")}
          </p>
        )}
      </div>

      {isCustomerModalOpen && (
        <CustomerModal
          onDelete={handleRemoveCustomer}
          isOpen={isCustomerModalOpen}
          onClose={() => setIsCustomerModalOpen(false)}
          isEditMode={Boolean(selectedCustomer)}
          selectedCustomer={selectedCustomer}
          onSave={handleSave}
        />
      )}
    </div>
  );
}
