import { useTranslation } from "react-i18next";

const PurchaseDeleteModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-sm">
        <h2 className="text-lg font-semibold mb-4 text-black dark:text-white">
          {t("purchases.purchasesModal.purchasesModalConfirmTitle")}
        </h2>
        <p className="mb-6 text-gray-600 dark:text-gray-300">
          {t("purchases.purchasesModal.purchasesModalConfirmTitle")}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
          >
            {t("purchases.purchasesModal.purchasesModalConfirmCancelBTN")}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-md"
          >
            {t("purchases.purchasesModal.purchasesModalConfirmConfirmBTN")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDeleteModal;
