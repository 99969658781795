import { PencilSquareIcon } from "@heroicons/react/20/solid";
import OrderStatus from "../Dashboard-components/order-status";
import { useTranslation } from "react-i18next";

export default function LeadTable({ leads, onEditLead }) {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const { t } = useTranslation();

  return (
    <div className="overflow-auto max-h-[60vh] border rounded-md">
      <table className="w-full min-w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              t("leads.leadsTable.id"),
              t("leads.leadsTable.name"),
              t("leads.leadsTable.phone"),
              t("leads.leadsTable.whenToCall"),
              t("leads.leadsTable.status"),
              t("leads.leadsTable.actions"),
            ].map((heading, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leads.map((lead, index) => (
            <tr key={lead.id} className="border-t">
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="px-2 py-2 text-gray-800 dark:text-white">
                {lead.name}
              </td>
              <td className="px-2 py-2 text-gray-800 dark:text-white">
                {lead.phoneNumber || "Not provided"}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {lead.whenToCall
                  ? formatDateTime(lead.whenToCall)
                  : "No schedule set"}
              </td>
              <td className="py-2">
                <OrderStatus status={lead.status} />
              </td>
              <td className="px-9 py-2">
                <button
                  onClick={() => onEditLead(lead)}
                  className="text-green-600 hover:text-green-800"
                  title="Edit Lead"
                >
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
