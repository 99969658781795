import { createContext, useReducer } from "react";
import { customers } from "../../constants/customers-list";
import { data  } from "../../constants/data";

const initialValue = {
  originalData: data,  
  data: data ,          
  customersOriginal: customers,  
  customers: customers,
  sortBy: "Product",   
};


export const Context = createContext(initialValue);

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_ORDER":
    return {
      ...state,
      data: [...state.data, payload],
    };
    case "REMOVE_ORDER":
      return {
        ...state,
        data: state.data.filter((order) => order.id !== payload),
        customers: state.customers.filter((order) => order.id !== payload),
      };

    case "SET_SORT_BY":
      return {
        ...state,
        sortBy: payload,
      };

      case "SORT_ORDERS": 
      const sortedData = [...state.data];
      if (state.sortBy === "Date") {
        sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (state.sortBy === "Status") {
        const statusOrder = ["contracted", "potential", "interested"];
        sortedData.sort(
          (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
        );
      }
      return { ...state, data: sortedData };

    case "FILTER_BY_USER":
      const filteredData = state.originalData.filter((order) =>
        order.user.toLowerCase().includes(payload.toLowerCase())
      );
      return { ...state, data: filteredData };

    default:
      return state;
  }
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
