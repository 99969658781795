import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function CustomerModal({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedCustomer,
  onDelete,
}) {
  const { t } = useTranslation(); 
  const [customerData, setCustomerData] = useState({
    name: "",
    phoneNumber: "",
    quantityPurchased: "",
  });

  const token = process.env.REACT_APP_ACCESS_TOKEN;

  useEffect(() => {
    if (isEditMode && selectedCustomer) {
      setCustomerData(selectedCustomer);
    } else {
      resetForm();
    }
  }, [isEditMode, selectedCustomer]);

  const resetForm = () => {
    setCustomerData({
      name: "",
      phoneNumber: "",
      quantityPurchased: "",
    });
  };

  const handleChange = (key, value) => {
    setCustomerData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleSave = async () => {
    if (!customerData.name.trim() || !customerData.phoneNumber.trim()) {
      return;
    }
  
    try {
      const formattedCustomer = {
        name: customerData.name,
        phoneNumber: customerData.phoneNumber,
        quantityPurchased: customerData.quantityPurchased,
      };
  
  
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
  
      let response;
      if (isEditMode) {
        response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/api/customers/${customerData._id}`,
          {
            method: "PATCH",
            headers,
            body: JSON.stringify(formattedCustomer),
          }
        );
      } else {
        response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/api/customers`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(formattedCustomer),
          }
        );
      }
  
      if (!response.ok) {
        throw new Error(isEditMode ? "Failed to update the customer" : "Failed to create a new customer");
      }
  
      const customer = await response.json();
      onSave(customer);
  
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error saving customer:", error.message);
      alert("Failed to save customer. Please try again.");
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4 text-black dark:text-white">
          {isEditMode
            ? t("customers.customerModal.editCustomer")
            : t("customers.customerModal.addCustomer")}
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {/* name */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("customers.customerModal.name")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={customerData.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          {/* phone */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("customers.customerModal.phone")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={customerData.phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
            />
          </div>
        </div>
        {/* actions */}
        <div className="flex justify-end space-x-3">
          {isEditMode && (
            <button
              onClick={() => onDelete(customerData?._id)}
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
            >
              {t("customers.customerModal.delete")}
            </button>
          )}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
          >
            {t("customers.customerModal.close")}
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
          >
            {t("customers.customerModal.save")}
          </button>
        </div>
      </div>
    </div>
  );
}
