import { ArrowLeftStartOnRectangleIcon, Cog6ToothIcon, UserCircleIcon, WalletIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

export default function Dropdown({ avatar, username }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="flex gap-3 items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)} 
      >
        <img
          src={avatar}
          alt="Avatar"
          className="h-10 w-10 object-fill rounded-full border-2 border-gray-700"
        />
        <span className="font-semibold">{username}</span>
      </div>

      {isOpen && (
        <div className="absolute top-12 right-0 w-48 bg-white dark:bg-gray-700 rounded-lg shadow-md py-2">
          <ul className="text-sm text-gray-700 dark:text-gray-200">
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center gap-2">
              <UserCircleIcon className="w-5 h-5 text-[#37B5FE]"/>
              <span>Profile</span>
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center gap-2">
            <WalletIcon className="w-4 h-4 text-[#37B5FE]"/>
            <span>My Wallet</span>
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center gap-2">
              <Cog6ToothIcon className="w-4 h-4 text-[#37B5FE]"/>
              <span>Settings</span>
            </li>
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center gap-2">
              <ArrowLeftStartOnRectangleIcon className="w-4 h-4 text-red-700"/>
              <span>Log Out</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
