import { Link, useLocation, useNavigate } from "react-router-dom";
import navigationLinks from "../constants/links";
import { logo } from "../assets";
import { useTranslation } from "react-i18next";

export default function Sidebar({ isDarkMode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <aside
      className={`overflow-y-auto scrollbar-hide z-50 border-r fixed top-0 left-0 h-screen w-80 bg-[#FAFAFB] dark:bg-gray-800 ${
        isDarkMode ? "text-gray-300" : "text-gray-800"
      }`}
    >
      <div className="flex flex-col items-center justify-center h-32 border-gray-200 dark:border-gray-700 my-4 cursor-pointer">
        <img src={logo} alt="Logo" className="h-24" onClick={navigateToHome} />
      </div>

      <nav className="mb-10">
        {navigationLinks.map(({ route, display_name, icon }) => (
          <Link
            key={route}
            to={route}
            className={`flex items-center p-4 mx-3 rounded-md transition-all duration-200 ${
              location.pathname === route
                ? "bg-[#37B5FE] text-white"
                : isDarkMode
                ? "hover:bg-gray-700 text-gray-300"
                : "hover:bg-gray-200 text-gray-800"
            }`}
          >
            <i
              className={`${icon} text-[24px] mx-4 ${
                location.pathname === route ? "text-white" : "text-[#37B5FE]"
              }`}
            ></i>
            <span className="font-medium text-[16px]">
              {t(`sideBar.${display_name}`)}
            </span>
          </Link>
        ))}
      </nav>
    </aside>
  );
}
