import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function ContractTable({ contracts, onEditContract }) {
  const { t } = useTranslation();

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full min-w-[1000px] table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[ 
              t("contracts.table.contractTableId"),
              t("contracts.table.contractTableProduct"),
              t("contracts.table.contractTableCustomer"),
              t("contracts.table.contractTableUnits"),
              t("contracts.table.contractTableDate"),
              t("contracts.table.contractTablePrice"),
              t("contracts.table.contractTableManager"),
              t("contracts.table.contractTableTotalsum"),
              t("contracts.table.contractTableActions"),
            ].map((heading) => (
              <th
                key={heading}
                className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contracts?.map((contract, index) => (  // Removed `data` from contracts
            <tr key={contract._id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {contract.productName}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {contract.customer?.name || t("contracts.noCustomer")}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {contract.quantity || t("contracts.noUnits")}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {contract.contractDate
                  ? formatDateTime(contract.contractDate)
                  : t("contracts.noDate")}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {contract.totalAmount / contract.quantity || "0"}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {contract.manager?.name || t("contracts.noManager")}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {contract.totalAmount || t("contracts.noTotal")}
              </td>
              <td className="px-4 py-2">
                <button onClick={() => onEditContract(contract)}>
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
