import React from "react";
import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import CustomerContarctModal from "./customer-contracts-modal";
import { useTranslation } from "react-i18next";

export default function CustomerTable({
  customers,
  onEditCustomer,
  onDeleteCustomer,
}) {
  const [contractModal, setContractModal] = React.useState(false);
  const [customer, setCustomer] = React.useState([]);

  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              "id",
              "name",
              "phoneNumber",
              "totalSpend",
              "contracts",
              "actions",
            ].map((heading) => (
              <th
                key={heading}
                className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {t(`customers.customerTable.${heading}`)}{" "}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr key={customer._id} className="border-t">
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {customer.name}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {customer.phoneNumber}
              </td>
              <td className="px-14 py-2 text-gray-800 dark:text-white">
                {customer.quantityPurchased}
              </td>
              <td className="px-14 py-2 text-gray-800 dark:text-white">
                <button
                  onClick={() => {
                    setContractModal(true);
                    setCustomer(customer);
                  }}
                  className="text-green-600 hover:text-green-800 dark:text-green-400"
                >
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
              <td className="px-8 py-2 flex gap-4">
                <button
                  onClick={() => onEditCustomer(customer)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {contractModal && (
        <CustomerContarctModal onClose={setContractModal} customer={customer} />
      )}
    </div>
  );
}
