import React from "react";
import { useTranslation } from "react-i18next";

export default function OrderStatus({ status }) {
  const { t } = useTranslation();

  const orderStatusStyles = {
    contracted:
      "text-green-600 border border-green-600 rounded-full w-24 text-center px-3 py-2 text-sm",
    interested:
      "text-red-600 border border-red-600 rounded-full w-24 text-center px-3 py-2 text-sm",
    potential:
      "text-orange-600 border border-orange-600 rounded-full w-24 text-center px-4 py-2 text-sm",
    purchased:
      "text-blue-700 border border-blue-700 rounded-full w-24 text-center px-4 py-2 text-sm",
    offered:
      "text-purple-600 border border-purple-600 rounded-full w-24 text-center px-4 py-2 text-sm",
  };

  return (
    <span className={orderStatusStyles[status]}>{t(`status.${status}`)}</span>
  );
}
