import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function CustomerFilter({
  handleFilterByDate,
  contracts,
  handleFilterByProductName,
}) {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [activeSubFilter, setActiveSubFilter] = useState(null);

  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const toggleFilter = (filter) => {
    setSelectedFilter((prev) => (prev === filter ? null : filter));
    setActiveSubFilter(null);
  };

  const toggleSubFilter = (subFilter) => {
    setActiveSubFilter((prev) => (prev === subFilter ? null : subFilter));
  };

  const sortedDatesAsc = t("contracts.fiter.contractFilterByA");
  const sortedDatesDesc = t("contracts.fiter.contractFilterByD");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button
        className="flex items-center px-3 py-2 border rounded-md text-black dark:text-white focus:outline-none"
        onClick={() => toggleFilter("Filter")}
      >
        <div className="flex items-center gap-2">
          <span>{t("contracts.fiter.contractFilterTitle")}</span>
          <FunnelIcon className="w-5 h-5 text-[#37B5FE]" />
        </div>
      </button>

      {selectedFilter === "Filter" && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-60 bg-white dark:bg-slate-700 border rounded-md shadow-lg z-10"
        >
          <div className="relative">
            {/* Subfilter: Date */}
            <button
              className="flex justify-between w-full px-4 py-2 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500 border-b"
              onClick={() => toggleSubFilter("Date")}
            >
              {t("contracts.fiter.contractFilterBy")}
              <ChevronDownIcon
                className={`w-5 h-5 transition-transform ${
                  activeSubFilter === "Date" ? "rotate-180" : ""
                }`}
              />
            </button>
            {activeSubFilter === "Date" && (
              <div className="max-h-48 overflow-y-auto">
                <button
                  onClick={() => handleFilterByDate(sortedDatesAsc)}
                  className="text-sm block w-full font-medium text-left px-10 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  {sortedDatesAsc}
                </button>
                <button
                  onClick={() => handleFilterByDate(sortedDatesDesc)}
                  className="text-sm block w-full font-medium text-left px-10 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  {sortedDatesDesc}
                </button>
              </div>
            )}

            {/* Subfilter: Product Name */}
            <button
              className="flex justify-between w-full px-4 py-2 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500 border-b"
              onClick={() => toggleSubFilter("ProductName")}
            >
              {t("contracts.fiter.productNameFilter")}
              <ChevronDownIcon
                className={`w-5 h-5 transition-transform ${
                  activeSubFilter === "ProductName" ? "rotate-180" : ""
                }`}
              />
            </button>
            {activeSubFilter === "ProductName" && (
              <div className="max-h-48 overflow-y-auto">
                {contracts.map((contract, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      handleFilterByProductName(contract.productName)
                    }
                    className="text-sm block w-full font-medium text-left px-10 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                  >
                    {contract.productName}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
