import React, { useEffect, useState } from "react";
import { ProductTable } from "../components";
import Search from "../components/Navbar-components/search-bar";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

export default function StockProducts({ isDarkMode }) {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";

  const { t } = useTranslation();
  const token = process.env.REACT_APP_ACCESS_TOKEN;


  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const getProducts = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${base_URL}/products`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json", 
        },
      });
      const data = await res.json();
      setProducts(data);
      setFilteredProducts(data);
    } catch (error) {
      console.log("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    getProducts();
  }, []);
  

  useEffect(() => {
    setFilteredProducts(
      products.filter((product) =>
        product.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, products]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="min-h-screen ml-10 min-w-full pt-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("products.productsTitle")}
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4 mb-6">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-4">
          <h2 className="text-xl font-semibold text-black dark:text-white">
            {t("products.productTableTitle")}
          </h2>
          <Search
            isDarkMode={isDarkMode}
            query={searchQuery}
            onSearch={handleSearch}
          />
        </div>

        {isLoading ? (
          <Loader />
        ) : filteredProducts.length > 0 ? (
          <ProductTable products={filteredProducts} />
        ) : (
          <p className="text-gray-700 dark:text-gray-300">
            {t("products.productsNoProduct")}
          </p>
        )}
      </div>
    </div>
  );
}
