import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import OrderStatus from "../Dashboard-components/order-status";
import { useTranslation } from "react-i18next";

export default function OrderTable({ orders, handleToggleCallDuty }) {
  const { t } = useTranslation();

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <div className="overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              t("dashboard.table.name"),
              t("dashboard.table.phoneNumber"),
              t("dashboard.table.status"),
              t("dashboard.table.whenToCall"),
            ].map((heading) => (
              <th
                key={heading}
                className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
            <th className="px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order._id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {order.name}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {order.phoneNumber}
              </td>
              <td className="px-2 py-2">
                <OrderStatus status={order.status} />
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {order.whenToCall
                  ? formatDateTime(order.whenToCall)
                  : t("table.noScheduleSet")}
              </td>
              <td className="px-4 py-2">
                <button
                  onClick={() => handleToggleCallDuty(order._id)}
                  className="text-green-600 hover:text-green-500"
                >
                  <CheckIcon className="w-5 h-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
