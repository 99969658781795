import { useEffect, useState } from "react";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import {ContractDeleteModal} from "./contract-delete-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContractModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedContract,
}) => {
  const [contract, setContract] = useState({
    productId: "",
    customerId: "",
    price: "",
    quantity: "",
    managerId: "",
    contractDate: "",
  });

  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [currency, setCurrency] = useState("USD");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { t } = useTranslation();

  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";
  const token = process.env.REACT_APP_ACCESS_TOKEN;

  useEffect(() => { 
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_URL}/contracts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        const data = await response.json();
    
        if (data.success) {
          setProducts(data.data.map((item) => item.product));
          setCustomers(data.data.map((item) => item.customer));
          setManagers(data.data.map((item) => item.manager));
        } else {
          console.error("API muvaffaqiyatsiz:", data);
        }
      } catch (error) {
        console.error("API xato:", error);
      }
    };
    

    fetchData();
  }, []);

  useEffect(() => {
    if (isEditMode && selectedContract) {
      setContract({
        productId: selectedContract.product?._id || "",
        productName: selectedContract.product?.name || "",
        customerId: selectedContract.customer?._id || "",
        price: selectedContract.price || "",
        quantity: selectedContract.quantity || "",
        managerId: selectedContract.manager?._id || "",
        managerName: selectedContract.manager?.name || "",
        priceSymbol: selectedContract.currency === "USD" ? "$" : "UZS",
        contractDate: selectedContract.contractDate
          ? new Date(selectedContract.contractDate).toISOString().split('T')[0]
          : "",
      });
    } else {
      resetForm();
    }
  }, [isEditMode, selectedContract]);

  const resetForm = () => {
    setContract({
      productId: "",
      productName: "",
      customerId: "",
      price: "",
      quantity: "",
      managerId: "",
      managerName: "",
      contractDate: "",
      priceSymbol: "$",
    });
  };

  const handleChange = (key, value) => {
    if (key === "price" || key === "quantity") {
      value = Number(value);
    }
    setContract((prev) => ({ ...prev, [key]: value }));
  };

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value;
    setCurrency(selectedValue);

    const symbol = selectedValue === "USD" ? "$" : "UZS";
    setContract((prev) => ({ ...prev, priceSymbol: symbol }));
  };

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "UZS", label: "UZS" },
  ];

  useEffect(() => {
    setCurrency("UZS");
    setContract((prev) => ({
      ...prev,
      priceSymbol: "$",
      price: prev.price || "",
    }));
  }, []);
  

  const handleSave = async () => {
    if (
      !contract.productId ||
      !contract.customerId ||
      !contract.price ||
      !contract.quantity ||
      !contract.contractDate
    ) {
      toast.error("Barcha maydonlar to'ldirilishi kerak.");
      return;
    }

    try {
      const formattedContract = {
        productId: contract.productId,
        quantity: contract.quantity,
        price: contract.price,
        customer: contract.customerId,
        manager: contract.managerId,
        contractDate: contract.contractDate,
      };

      const url = isEditMode
        ? `${base_URL}/contracts/${selectedContract?._id}`
        : `${base_URL}/contracts`;

      const method = isEditMode ? "PATCH" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify(formattedContract),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        console.error("Error response:", responseData);
        throw new Error(
          `${method === "PATCH" ? "Yangilashda" : "Yaratishda"} xato: ${
            responseData.message || "Noma'lum xato"
          }`
        );
      }
  
      toast.success("Kontrakt muvaffaqiyatli saqlandi!");
      window.location.reload();
      resetForm();
      onClose();
    } catch (error) {
      console.error("Kontraktni saqlashda xato:", error);
      toast.error("Kontraktni saqlashda muammo yuz berdi. Iltimos qayta urinib ko'ring.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${base_URL}/contracts/${selectedContract._id}`,
        {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`, 
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Kontraktni o'chirishda xato.");
      }
  
      toast.success("Kontrakt muvaffaqiyatli o'chirildi!");
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Kontraktni o'chirishda xato:", error);
      toast.error(
        "Kontraktni o'chirishda muammo yuz berdi. Iltimos qayta urinib ko'ring."
      );
    }
  };
  

  if (!isOpen) return null;

  
  const uniqueProducts = Array.from(
    new Map(products.map((product) => [product._id, product])).values()
  );
  
  const uniqueCustomers = Array.from(
    new Map(customers.map((customer) => [customer._id, customer])).values()
  );
  
  const uniqueManagers = Array.from(
    new Map(managers.map((manager) => [manager._id, manager])).values()
  );
  const productOptions = uniqueProducts.map((product) => ({
    value: product._id,
    label: product.name,
  }));
  
  const customerOptions = uniqueCustomers.map((customer) => ({
    value: customer._id,
    label: customer.name,
  }));
  
  const managerOptions = uniqueManagers.map((manager) => ({
    value: manager._id,
    label: manager.name,
  }));

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          {isEditMode
            ? t("contracts.contractsModal.contractEdit")
            : t("contracts.contractsModal.contractAdd")}
        </h2>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="dark:text-white">{t("contracts.contractsModal.contractProduct")}</label>
            <Select
              options={productOptions}
              value={productOptions.find(
                (option) => option.value === contract.productId
              )}
              onChange={(selectedOption) =>
                handleChange("productId", selectedOption?.value || "")
              }
            />
          </div>

          <div>
          <label className="dark:text-white">{t("contracts.contractsModal.contractCustomer")}</label>
          <Select
            options={customerOptions}
            value={customerOptions.find(
              (option) => option.value === contract.customerId
            )}
            onChange={(selectedOption) => {
              handleChange("customerId", selectedOption ? selectedOption.value : "");
            }}
          />
        </div>

        <div>
  <label className="dark:text-white">{t("contracts.contractsModal.contractPrice")}</label>
  <div className="relative flex items-center">
    <span className="absolute left-2 text-gray-500">{contract.priceSymbol}</span>
    <input
      type="number"
      value={contract.price || ""}
      onChange={(e) => handleChange("price", e.target.value)}
      className="w-full pl-10 pr-3 py-2 border rounded-l-md"
    />
    <select
      value={currency}
      onChange={handleCurrencyChange}
      className="px-3 py-[10px] border-l-0 rounded-r-md bg-gray-50"
    >
      {currencyOptions.map((currencyOption) => (
        <option key={currencyOption.value} value={currencyOption.value}>
          {currencyOption.label}
        </option>
      ))}
    </select>
  </div>
</div>


          <div>
            <label className="dark:text-white">Qunatity</label>
            <input
              type="number"
              value={contract.quantity || ""}
              onChange={(e) => handleChange("quantity", e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>

          <div>
            <label className="dark:text-white">{t("contracts.contractsModal.contractManager")}</label>
            <Select
              options={managerOptions}
              value={managerOptions.find(
                (option) => option.value === contract.managerId
              )}
              onChange={(selectedOption) => {
                handleChange("managerId", selectedOption ? selectedOption.value : "");
              }}
            />
          </div>

          <div>
            <label className="dark:text-white">{t("contracts.contractsModal.contractDate")}</label>
            <input
              type="date"
              value={contract.contractDate || ""}
              onChange={(e) => handleChange("contractDate", e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
            onClick={onClose}
          >
            {t("contracts.contractsModal.contractCancelBTN")}
          </button>

          {isEditMode && (
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={() => setShowConfirmModal(true)}
            >
              {t("contracts.contractsModal.contractDeleteBTN")}
            </button>
          )}

          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleSave}
          >
           {t("contracts.contractsModal.contractSaveBTN")}
          </button>
        </div>

        {showConfirmModal && (
  <ContractDeleteModal
    isOpen={showConfirmModal}  
    onClose={() => setShowConfirmModal(false)}
    onConfirm={handleDelete}  
  />
)}

      </div>

      <ToastContainer />
    </div>
  );
};

export default ContractModal;