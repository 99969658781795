import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import PurchaseDeleteModal from "./purchase-delete-modal";
import { useTranslation } from "react-i18next";

const PurchaseModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedPurchase,
  productNames,
}) => {
  const [purchase, setPurchase] = useState({
    supplierInfo: "",
    productName: "",
    quantity: "",
    quantityForFree: "",
    price: "",
    additionalCost: "",
    date: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { t } = useTranslation();
  const token = process.env.REACT_APP_ACCESS_TOKEN; 

  useEffect(() => {
    if (isEditMode && selectedPurchase) {
      setPurchase({
        supplierInfo: selectedPurchase.supplierInfo || "",
        productName: selectedPurchase.productName || "",
        quantity: selectedPurchase.quantity || "",
        quantityForFree: selectedPurchase.quantityForFree || "",
        price: selectedPurchase.price || "",
        additionalCost: selectedPurchase.additionalCost || "",
        date: selectedPurchase.purchaseDate || "",
      });
    } else {
      resetForm();
    }
  }, [isEditMode, selectedPurchase]);

  const resetForm = () => {
    setPurchase({
      supplierInfo: "",
      productName: "",
      quantity: "",
      quantityForFree: "",
      price: "",
      additionalCost: "",
      date: "",
    });
  };

  const handleChange = (key, value) => {
    setPurchase((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = async () => {
    if (
      !purchase.productName ||
      !purchase.quantity ||
      !purchase.price ||
      !purchase.date
    ) {
      alert("All fields are required.");
      return;
    }
  
  
    try {
      const formattedPurchase = {
        supplierInfo: purchase.supplierInfo,
        productName: purchase.productName,
        quantity: parseInt(purchase.quantity),
        quantityForFree: parseInt(purchase.quantityForFree) || 0,
        price: parseFloat(purchase.price || purchase.cost),
        additionalCost: parseFloat(purchase.additionalCost) || 0,
        purchaseDate: purchase.date,
      };
  
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      };
  
      if (isEditMode) {
        const response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/api/purchases/${selectedPurchase._id}`,
          {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(formattedPurchase),
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error("Error response:", errorData);
          throw new Error("Failed to update the purchase");
        }
  
        window.location.reload();
      } else {
        const response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/api/purchases`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formattedPurchase),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to create a new purchase");
        }
  
        window.location.reload();
      }
  
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error saving purchase:", error.message);
      alert("Failed to save purchase. Please try again.");
    }
  };
  
  const handleDelete = async () => {
  
    try {
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/api/purchases/${selectedPurchase._id}`,
        {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to delete the purchase");
      }
  
      window.location.reload();
      onClose();
    } catch (error) {
      console.error("Error deleting purchase:", error.message);
      alert("Failed to delete purchase. Please try again.");
    }
  };
  
  const confirmDelete = () => {
    setShowConfirmModal(true);
  };
  

  if (!isOpen) return null;

  const productOptions = productNames.map((name) => ({
    value: name,
    label: name,
  }));

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          {isEditMode ? "Edit Purchase" : "Add Purchase"}
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalSupplier")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.supplierInfo || ""}
              onChange={(e) => handleChange("supplierInfo", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalName")}
            </label>
            <CreatableSelect
              isClearable
              options={productOptions}
              value={productOptions.find(
                (option) => option.value === purchase.productName
              )}
              onChange={(selectedOption) =>
                handleChange("productName", selectedOption?.value || "")
              }
              className="mt-1"
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalQuantity")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.quantity || ""}
              onChange={(e) => handleChange("quantity", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalQuantityForFree")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.quantityForFree || ""}
              onChange={(e) => handleChange("quantityForFree", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalPrice")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.price || ""}
              onChange={(e) => handleChange("price", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalAdditionalCost")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.additionalCost || ""}
              onChange={(e) => handleChange("additionalCost", e.target.value)}
            />
          </div>

          <div className="col-span-2">
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("purchases.purchasesModal.purchasesModalDate")}
            </label>
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.date}
              onChange={(e) => handleChange("date", e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-3">
          {isEditMode && (
            <button
              onClick={confirmDelete}
              className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-md"
            >
              {t("purchases.purchasesModal.purchasesModalDeleteBTN")}
            </button>
          )}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
          >
            {t("purchases.purchasesModal.purchasesModalCloseBTN")}
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
          >
            {t("purchases.purchasesModal.purchasesModalSaveBTN")}
          </button>
        </div>
      </div>
      <PurchaseDeleteModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          handleDelete();
        }}
      />
    </div>
  );
};

export default PurchaseModal;
