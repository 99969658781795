
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { user } from "../../assets";
import {BsDot} from 'react-icons/bs'

const LeadModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedLead,
  onDelete,
}) => {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";
  const token = process.env.REACT_APP_ACCESS_TOKEN;
  const commentsEndRef = useRef(null);

  const [lead, setLead] = useState({
    name: "",
    phoneNumber: "",
    whenToCall: new Date().toISOString().slice(0, 16),
    status: "interested",
    comments: [],
  });

  const handleClose = () => {
    onClose()
    window.location.reload()
  }

  const [newComment, setNewComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);

  const { t } = useTranslation();
  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  
  
  useEffect(() => {
    if (isOpen && lead.comments.length > 0) {
      scrollToBottom();
    }
  }, [isOpen, lead.comments]);
  

  useEffect(() => {
    if (isEditMode && selectedLead) {
      setLead({
        ...selectedLead,
        whenToCall: new Date(selectedLead.whenToCall)
          .toISOString()
          .slice(0, 16),
      });
    } else {
      setLead({
        name: "",
        phoneNumber: "",
        whenToCall: new Date().toISOString().slice(0, 16),
        status: "interested",
        comments: [],
      });
    }
    setNewComment("");
    setEditingComment(null);
  }, [isEditMode, selectedLead]);

  const handleChange = (key, value) => {
    setLead((prev) => ({ ...prev, [key]: value }));
  };


const handleSave = async () => {
  if (!lead.name.trim() || !lead.phoneNumber.trim()) {
    alert("Name va telefon raqamini to‘ldiring.");
    return;
  }

  try {
    const url = isEditMode ? `${base_URL}/leads/${lead._id}` : `${base_URL}/leads`;
    const method = isEditMode ? "PATCH" : "POST";

    const response = await fetch(url, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,  
      },
      body: JSON.stringify({
        name: lead.name,
        phoneNumber: lead.phoneNumber,
        status: lead.status,
        whenToCall: new Date(lead.whenToCall).toISOString(),
        comments: lead.comments,
      }),
    });

    if (!response.ok) throw new Error("Lead saqlashda xatolik yuz berdi.");

    const savedLead = await response.json();
    onSave(savedLead);
    onClose();
  } catch (error) {
    console.error(error.message);
    alert("Saqlashda muammo yuz berdi.");
  }
};

const handleDelete = async () => {
  if (!selectedLead?._id) return;

  try {
    const response = await fetch(`${base_URL}/leads/${selectedLead._id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Authorization": `Bearer ${token}`,  
      },
    });

    if (!response.ok) throw new Error("Lead o‘chirishda xatolik yuz berdi.");

    onDelete(selectedLead._id);
    onClose();
  } catch (error) {
    console.error(error.message);
    alert("O‘chirishda muammo yuz berdi.");
  }
};

const handleAddComment = async () => {
  if (!newComment.trim()) return;

  const timestamp = new Date().toISOString();

  const newCommentData = {
    text: newComment,
    timestamp: timestamp,
  };

  setLead((prev) => ({
    ...prev,
    comments: [...prev.comments, newCommentData],
  }));

  setNewComment("");

  try {
    const response = await fetch(`${base_URL}/leads/${lead._id}/comments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,  
      },
      body: JSON.stringify(newCommentData),
    });

    if (!response.ok)
      throw new Error("Comment qo‘shishda xatolik yuz berdi.");
  } catch (error) {
    console.error(error.message);
    alert("Comment qo‘shishda muammo yuz berdi.");
  }
};

const handleSaveComment = async (index) => {
  const updatedComment = {
    text: newComment,
    timestamp: new Date().toISOString(),
  };

  try {
    const response = await fetch(`${base_URL}/leads/${lead._id}/comments`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      },
      body: JSON.stringify(updatedComment),
    });

    if (!response.ok)
      throw new Error("Commentni tahrirlashda xatolik yuz berdi.");

    const savedComment = await response.json();
    const updatedComments = [...lead.comments];
    updatedComments[index] = savedComment;

    setLead((prev) => ({
      ...prev,
      comments: updatedComments,
    }));

    setEditingComment(null);
    setNewComment("");
  } catch (error) {
    console.error(error.message);
    alert("Commentni tahrirlashda muammo yuz berdi.");
  }
};



  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
      <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
  {isEditMode
    ? t("leads.leadmodal.leadsModalTitleEdit") 
    : t("leads.leadmodal.leadsModalTitleAdd")} 
</h2>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("leads.leadmodal.name")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={lead.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("leads.leadmodal.phone")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={lead.phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("leads.leadmodal.whenToCall")}
            </label>
            <input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded-md"
              value={lead.whenToCall}
              onChange={(e) => handleChange("whenToCall", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("leads.leadmodal.status")}
            </label>
            <select
              className="w-full px-3 py-2 border rounded-md"
              value={lead.status}
              onChange={(e) => handleChange("status", e.target.value)}
            >
              <option value="interested">
                {t("leads.leadmodal.interested")}
              </option>
              <option value="contracted">
                {t("leads.leadmodal.contracted")}
              </option>
              <option value="potential">
                {t("leads.leadmodal.potential")}
              </option>
              <option value="purchased">
                {t("leads.leadmodal.purchased")}
              </option>
              <option value="offered">{t("leads.leadmodal.offered")}</option>
            </select>
          </div>
        </div>

        <div>
  <h3 className="text-lg font-semibold mb-2 dark:text-white">
    {t("leads.leadmodal.commentTitle")}
  </h3>
  <div className="mb-4 max-h-48 overflow-y-auto custom-scrollbar">
  {lead?.comments?.length > 0 ? (
    lead.comments.map((comment, index) => (
      <div
        key={index}
        className="flex items-start gap-3 p-3 bg-white dark:bg-gray-800 rounded-lg"
      >
        <img
          src={user}
          alt="Avatar"
          className="w-10 h-10 rounded-full"
        />
        <div className="flex flex-col w-full">
          <div className="flex gap-1 items-center">
            <p className="text-sm font-semibold text-gray-800 dark:text-white">
              User
            </p>
            <BsDot className="w-5 h-5 text-gray-400" />
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {new Date(comment.timestamp).toLocaleString()}
            </p>
          </div>
          <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
            {comment.text}
          </p>
        </div>
      </div>
    ))
  ) : (
    <p className="text-sm text-gray-500 dark:text-gray-400">
      No comments
    </p>
  )}
  <div ref={commentsEndRef} /> 
</div>


  <div className="flex items-center gap-3 mt-4">
    <textarea
    rows={1}
      type="text"
      value={newComment}
      onChange={(e) => setNewComment(e.target.value)}
      className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white"
      placeholder={t("leads.leadmodal.commentPlaceholder")}
    />
    <button
      onClick={
        editingComment !== null
          ? () => handleSaveComment(editingComment)
          : handleAddComment
      }
      className="bg-blue-600 text-white px-4 py-2 rounded-md"
    >
      {editingComment !== null
        ? t("leads.leadmodal.commentSave")
        : t("leads.leadmodal.commentSend")}
    </button>
  </div>
</div>


        <div className="mt-4 flex justify-end gap-2">
          <button
            onClick={handleClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          >
            {t("leads.leadmodal.closeButton")}
          </button>
          {isEditMode && (
            <button
              onClick={handleDelete}
              className="bg-red-600 text-white px-4 py-2 rounded-md "
            >
              {t("leads.leadmodal.deleteButton")}
            </button>
          )}
          <button
            onClick={handleSave}
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
          >
            {t("leads.leadmodal.saveButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeadModal;
