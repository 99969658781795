import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Conversions = () => {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";

  const [barValues, setBarValues] = useState([]);
  const [barNames, setBarNames] = useState([]);
  const [getLeadsData, setGetLeadsData] = useState([]);
  const [animatedValues, setAnimatedValues] = useState([]);
  
  const token = process.env.REACT_APP_ACCESS_TOKEN;

  const { t } = useTranslation();

  const fetchLeads = async () => {
    try {
      const response = await fetch(`${base_URL}/leads`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
  
      if (response.ok) {
        setGetLeadsData(data.data || []);
      } else {
        console.error("Leadsni olishda xato:", data.message || "Noma'lum xato");
      }
    } catch (error) {
      console.error("Leadsni olishda xato:", error);
    }
  };
  

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    if (getLeadsData.length > 0) {
      const statuses = getLeadsData.map((item) => item.status);
      const uniqueStatuses = Array.from(new Set(statuses));

      setBarNames(uniqueStatuses);

      const statusCounts = uniqueStatuses.map(
        (status) => statuses.filter((item) => item === status).length
      );
      setBarValues(statusCounts);
    }
  }, [getLeadsData]);

  useEffect(() => {
    if (barValues.length > 0) {
      setAnimatedValues(new Array(barValues.length).fill(0));

      const duration = 1000; // 5 seconds
      const steps = 100; // Number of animation steps
      const interval = duration / steps;

      let currentStep = 0;

      const intervalId = setInterval(() => {
        currentStep++;
        setAnimatedValues((prev) =>
          prev.map((value, index) =>
            Math.min(value + barValues[index] / steps, barValues[index])
          )
        );

        if (currentStep >= steps) {
          clearInterval(intervalId); // Stop the animation
        }
      }, interval);

      return () => clearInterval(intervalId);
    }
  }, [barValues]);

  return (
    <div className="min-h-screen ml-10 min-w-full pt-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("conversions.conversionsTitle")}
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4 mb-6">
        <div className="flex justify-between items-center gap-4 mb-4">
          <h2 className="text-xl font-semibold text-black dark:text-white">
            {t("conversions.conversionsTitle")}
          </h2>
        </div>
        <div className="mt-10 w-full max-w-[80%] pr-10">
          <div className="space-y-10">
            {animatedValues.map((value, index) => (
              <div
                key={index}
                className="flex items-center space-x-2"
                style={{ height: "30px", borderRadius: "4px" }}
              >
                <div
                  className="bg-blue-500 transition-all duration-500 ease-out"
                  style={{
                    width: `${(value / Math.max(...barValues)) * 100}%`,
                    height: "150%",
                    borderRadius: "4px",
                  }}
                ></div>
                <span className="text-base text-black dark:text-gray-300">
                  {barNames[index]}:
                </span>
                <span className="text-base text-blue-800 dark:text-white font-semibold">
                  {value === 0 ? "It is empty" : Math.round(value)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversions;
