import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function ProductTable({ products, onEditProduct }) {
  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              t("products.productTable.productTableId"),
              t("products.productTable.productTableName"),
              t("products.productTable.productTablePurchased"),
              t("products.productTable.productTableSold"),
              t("products.productTable.productTableStock"),
            ].map((heading) => (
              <th
                key={heading}
                className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products?.map((product, index) => (
            <tr key={product._id} className="border-t">
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="px-2 py-2 text-gray-800 dark:text-white">
                {product.name}
              </td>
              <td className="px-10 py-2 text-gray-800 dark:text-white">
                {product.purchased}
              </td>
              <td className="px-8 py-2 text-gray-800 dark:text-white">
                {product.quantitySold}
              </td>
              <td className="px-8 py-2 text-gray-800 dark:text-white">
                {product.stock}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
