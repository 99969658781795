import { Route, Routes } from "react-router-dom";
import {
  Contracts,
  Conversions,
  Customers,
  Dashboard,
  Leads,
  Managers,
  Purchases,
  StockProducts,
} from "./ui";
import Login from "./ui/Login";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/leads" element={<Leads />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/contracts" element={<Contracts />} />
      <Route path="/products" element={<StockProducts />} />
      <Route path="/managers" element={<Managers />} />
      <Route path="/purchases" element={<Purchases />} />
      <Route path="/conversions" element={<Conversions />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default App;
