import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";

export default function Search({ isDarkMode, onSearch }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation();

  const handleSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div
      className={`relative rounded-md overflow-hidden transition-all duration-700 ease-in-out`}
      style={{
        width: isExpanded ? "60%" : "30%",
        minWidth: 200,
      }}
    >
      <input
        type="text"
        onFocus={() => setIsExpanded(true)}
        onBlur={() => setIsExpanded(false)}
        onChange={handleSearch}
        className={`w-full ${
          isDarkMode ? "bg-gray-800 text-gray-300" : "bg-gray-100 text-gray-800"
        } pl-10 pr-4 py-2 rounded-lg focus:outline-none`}
        placeholder={t("search.searchPlaceholder")}
      />
      <div
        className={`absolute left-3 top-2.5 ${
          isDarkMode ? "text-gray-400" : "text-gray-500"
        }`}
      >
        <CiSearch className="h-5 w-5 cursor-pointer hover:text-[#37B5FE]" />
      </div>
    </div>
  );
}
