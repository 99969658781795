import React, { useState, useEffect } from "react";
import ManagerDeleteModal from "./manager-delete-modal";
import { useTranslation } from "react-i18next";

export default function ManagersModal({
  isOpen,
  manager,
  isEditMode,
  onClose,
  onSave,
}) {
  const [formData, setFormData] = useState({
    name: manager?.name || "",
    phone: manager?.phone || "",
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (manager) {
      setFormData({
        name: manager.name || "",
        phone: manager.phone || "",
      });
    }
  }, [manager]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/api/managers/${manager._id}`,
        { method: "DELETE" }
      );
      window.location.reload();
      const data = await response.json();

      if (response.ok && data.success) {
        onSave((prevManagers) =>
          prevManagers.filter((m) => m._id !== manager._id)
        );
        setShowConfirmModal(false);
        onClose();
      } else {
        console.error(
          "Failed to delete manager:",
          data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error deleting manager:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { name: formData.name, phone: formData.phone };

    try {
      const response = await fetch(
        isEditMode
          ? `https://amalify-server-82d7e0343c9c.herokuapp.com/api/managers/${manager._id}`
          : `https://amalify-server-82d7e0343c9c.herokuapp.com/api/managers`,
        {
          method: isEditMode ? "PATCH" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      if (data.success) {
        onSave(data.data);
        onClose();
      }
    } catch (error) {
      console.error("Error saving manager:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold mb-4 text-black dark:text-white">
          {isEditMode
            ? t("managers.managerModal.managerModalEdit")
            : t("managers.managerModal.managerModalAdd")}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t("managers.managerModal.managerModalName")}
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full border rounded-md p-2 mt-1 dark:bg-gray-700 dark:text-white"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t("managers.managerModal.managerModalPhone")}
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full border rounded-md p-2 mt-1 dark:bg-gray-700 dark:text-white"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            {isEditMode && (
              <button
                type="button"
                onClick={() => setShowConfirmModal(true)} // `onClose` chaqirilmaydi
                className="px-4 py-2 bg-red-600 dark:bg-red-500 rounded-md text-white"
              >
                {t("managers.managerModal.managerModalDeleteBTN")}
              </button>
            )}
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 dark:bg-gray-700 rounded-md text-black dark:text-white"
            >
              {t("managers.managerModal.managerModalCancelBTN")}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
            >
              {isEditMode
                ? t("managers.managerModal.managerModalSaveBTN")
                : t("managers.managerModal.managerModalAddBTN")}
            </button>
          </div>
        </form>
      </div>
      <ManagerDeleteModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
}
