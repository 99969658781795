import React, { useEffect, useState, useRef } from "react";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { avatar } from "../assets";
import { NotificationBell, Dropdown } from "../components";

const flags = {
  en: "/ukFlag.png",
  uz: "/uzFlag.png",
  ru: "/ruFlag.png",
};

export default function Navbar({ isDarkMode, toggleTheme, changeLang }) {
  const [selectedLang, setSelectedLang] = useState("en");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      setSelectedLang(savedLang);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeLangHandler = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem("language", lang);
    changeLang(lang);
    setDropdownOpen(false);
  };

  return (
    <nav
      className="bg-[#FAFAFB] dark:bg-gray-800 text-gray-800 dark:text-white py-6 fixed top-0 left-80 w-[calc(100%-20rem)] z-10"
      style={{ height: "5rem" }}
    >
      <div className="flex flex-row items-center justify-end px-8 h-full">
        <div className="flex items-center space-x-8">
          <Dropdown avatar={avatar} username="Shakhreek" />

          <NotificationBell />

          <button
            onClick={toggleTheme}
            className="text-[#37B5FE] hover:cursor-pointer focus:outline-none"
          >
            {isDarkMode ? (
              <MdOutlineLightMode className="h-8 w-8" />
            ) : (
              <MdOutlineDarkMode className="h-8 w-8" />
            )}
          </button>

          <div className="relative" ref={dropdownRef}>
            <button
              className="bg-gray-200 w-[70px] dark:bg-gray-700 text-gray-800 dark:text-white p-2 rounded-md flex items-center space-x-2"
              onClick={() => setDropdownOpen((prev) => !prev)}
            >
              <img
                src={flags[selectedLang]}
                alt={`${selectedLang} flag`}
                className="h-4 w-4"
              />
              <span>{selectedLang.toUpperCase()}</span>
            </button>
            {dropdownOpen && (
              <ul className="absolute bg-white dark:bg-gray-700 mt-2 p-2 rounded-md shadow-lg">
                {Object.entries(flags).map(([lang, flag]) => (
                  <li
                    key={lang}
                    className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
                    onClick={() => changeLangHandler(lang)}
                  >
                    <img src={flag} alt={`${lang} flag`} className="h-4 w-4" />
                    <span>{lang.toUpperCase()}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
