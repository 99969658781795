export const data= [
{ id: "1", code: "#OD1711", user: "john doe", date: "17 Jun 2021", price: "$900", status: "interested", product: "Ozone Generator" },
{ id: "2", code: "#OD1712", user: "frank iva", date: "1 Jun 2021", price: "$400", status: "potential", product: "Ozone Generator" },
{ id: "3", code: "#OD1712", user: "frank iva", date: "1 Jun 2021", price: "$400", status: "interested", product: "Ozone Generator" },
{ id: "4", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "interested", product: "Ozone Generator" },
{ id: "5", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "potential", product: "Ozone Generator" },
{ id: "6", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "potential", product: "Ozone Generator" },
{ id: "7", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "contracted", product: "Ozone Generator" },
{ id: "8", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "contracted", product: "Ozone Generator" },
{ id: "9", code: "#OD1713", user: "anthony baker", date: "27 Jun 2021", price: "$200", status: "contracted", product: "Ozone Generator" },
]