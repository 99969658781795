import { useState } from "react";

const OrderModal = ({ isOpen, onClose, onSave }) => {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";

  const [order, setOrder] = useState({
    name: "",
    phoneNumber: "",
    status: "interested",
    whenToCall: new Date().toISOString().slice(0, 16),
    callDuty: false,
  });

  const handleChange = (key, value) => {
    setOrder((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = async () => {
    if (order.name.trim() === "" || order.phoneNumber.trim() === "") {
      console.error("Name or phone number is empty!");
      return;
    }
  
    try {
      const response = await fetch(`${base_URL}/leads`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: order.name,
          phoneNumber: order.phoneNumber,
          status: order.status,
          whenToCall: new Date(order.whenToCall).toISOString(),
          callDuty: order.callDuty,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to create order");
      }
      window.location.reload()
      const savedOrder = await response.json();
      onSave(savedOrder); 
      onClose(); 
    } catch (error) {
      console.error("Error saving order:", error);
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          Add Order
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={order.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              Phone Number
            </label>
            <input
              type="phone"
              className="w-full px-3 py-2 border rounded-md"
              value={order.phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              Delivery Date
            </label>
            <input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded-md"
              value={
                order.whenToCall
                  ? new Date(order.whenToCall).toISOString().slice(0, 16)
                  : ""
              }
              onChange={(e) => handleChange("whenToCall", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              Status
            </label>
            <select
              className="w-full px-3 py-2 border rounded-md"
              value={order.status}
              onChange={(e) => handleChange("status", e.target.value)}
            >
              <option value="interested">Interested</option>
              <option value="contracted">Contracted</option>
              <option value="potential">Potential</option>
              <option value="purchased">Purchased</option>
              <option value="offered">Offered</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={handleSave}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
