import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function PurchaseTable({ purchases, onEditPurchase }) {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full min-w-[1000px] table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            {[
              t("purchases.purchasesTable.purchasesTableId"),
              t("purchases.purchasesTable.purchasesTableSupplier"),
              t("purchases.purchasesTable.purchasesTableItems"),
              t("purchases.purchasesTable.purchasesTableQuantity"),
              t("purchases.purchasesTable.purchasesTableFree"),
              t("purchases.purchasesTable.purchasesTableAdditionalCost"),
              t("purchases.purchasesTable.purchasesTablePrice"),
              t("purchases.purchasesTable.purchasesTableTotal"),
              t("purchases.purchasesTable.purchasesTableDate"),
              t("purchases.purchasesTable.purchasesTableActions"),
            ].map((heading) => (
              <th
                key={heading}
                className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase, index) => (
            <tr key={purchase.id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.supplierInfo}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.productName}
              </td>
              <td className="px-6 py-2 text-gray-800 dark:text-white">
                {purchase.quantity}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.quantityForFree}
              </td>
              <td className="px-8 py-2 text-gray-800 dark:text-white">
                {purchase.additionalCost}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.price || purchase.cost}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.totalCost}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white">
                {purchase.purchaseDate}
              </td>
              <td className="px-4 py-2">
                <button onClick={() => onEditPurchase(purchase)}>
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
