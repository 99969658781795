import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";

const PurchaseFilter = ({
  uniqueItems = [],
  callDates = [],
  handleFilterByItem,
  handleFilterByDate,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleSubFilter = (filter) =>
    setActiveFilter((prev) => (prev === filter ? null : filter));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center gap-2 px-4 py-2 border rounded-md bg-white dark:bg-gray-700 dark:text-white text-black focus:outline-none"
      >
        <span>Filter</span>
        <FunnelIcon className="w-5 h-5 text-[#37B5FE]" />
      </button>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-700 border rounded-lg shadow-lg z-30"
        >
          <div className="relative">
            <button
              className="w-full text-left px-4 py-2 text-md text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
              onClick={() => toggleSubFilter("date")}
            >
              Filter by Date
            </button>
            {activeFilter === "date" && (
              <div >
                <button
                  onClick={() => handleFilterByDate(true)}
                  className="w-full px-8 text-left text-sm text-black dark:text-white py-2 hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  Ascending
                </button>
                <button
                  onClick={() => handleFilterByDate(false)}
                  className="w-full px-8 text-left text-sm text-black dark:text-white py-2 hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  Descending
                </button>
              </div>
            )}
          </div>

          <div className="relative">
  <button
    className="w-full text-left px-4 py-2 text-md text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
    onClick={() => toggleSubFilter("product")}
  >
    Filter by Items
  </button>
  {activeFilter === "product" && (
    <div>
      {uniqueItems.map((item) => (
        <div className="w-full h-full hover:bg-gray-100 dark:hover:bg-gray-500">
        <button
          key={item}
          className="w-full text-left text-sm text-black dark:text-white mb-3 px-8" 
          onClick={() => {
            handleFilterByItem(item);
            setActiveFilter(null);
          }}
        >
          {item}
        </button>
        </div>
      ))}
    </div>
  )}
</div>


        </div>
      )}
    </div>
  );
};

export default PurchaseFilter;
