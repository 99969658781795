import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ContractModal, ContractTable } from "../components";
import Search from "../components/Navbar-components/search-bar";
import ContractFilter from "../components/Contracts-components/contract-filter";
import Loader from "./Loader";
import { ToastContainer } from "react-toastify";
import showToast from "./Toast";
import { useTranslation } from "react-i18next";

export default function Contracts({ isDarkMode }) {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";
  const { t } = useTranslation();

  const [filteredContracts, setFilteredContracts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedContractType, setSelectedContractType] = useState("");
  
  const token = process.env.REACT_APP_ACCESS_TOKEN;

  const getContracts = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(`${base_URL}/contracts`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
      const data = await res.json();
      console.log("Contracts:", data);
  
      if (Array.isArray(data)) {
        setContracts(data); 
      } else if (data && Array.isArray(data.data)) {
        setContracts(data.data);
      } else {
        console.error("Unexpected data structure:", data);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Contracts Fetch Error:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const filtered = contracts.filter((contract) => {
      const matchesType = selectedContractType === "" || contract.contractType === selectedContractType;
      return matchesType;
    });
    setFilteredContracts(filtered);
  }, [contracts, selectedContractType]);


  useEffect(() => {
    getContracts();
  }, []);

  useEffect(() => {
    if (contracts.length > 0) {
      setFilteredContracts(contracts);  
    }
  }, [contracts]);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();

    if (query === "") {
      setFilteredContracts(contracts);
      return;
    }

    const filtered = Array.isArray(contracts)
      ? contracts.filter((contract) => {
          return (
            contract.productName?.toLowerCase().includes(lowerCaseQuery) ||
            contract.customer?.name?.toLowerCase().includes(lowerCaseQuery) ||
            contract.price?.toString().includes(lowerCaseQuery)
          );
        })
      : []; 

    setFilteredContracts(filtered);
  };
  

  const handleFilterByDate = (isAscending) => {
    const sortedContracts = [...contracts].sort((a, b) => {
      const dateA = new Date(a.contractDate).getTime();
      const dateB = new Date(b.contractDate).getTime();
      return isAscending ? dateA - dateB : dateB - dateA;
    });
    setFilteredContracts(sortedContracts);
    console.log("Sorted Contracts by Date:", sortedContracts); // Debug
  };
  

  const handleFilterByProductName = (productName) => {
    const filteredContracts = contracts.filter(
      (contract) => contract.product.name === productName
    );
    setFilteredContracts(filteredContracts);
    console.log("Filtered Contracts by Product Name:", filteredContracts); 
  };
  
  const uniqueProducts = [
    ...new Set(contracts.map((contract) => contract.product.name)),
  ];
  console.log("Unique Products:", uniqueProducts); // Debug
  

  const addContracts = async (contract) => {
    const res = await fetch(`${base_URL}/contracts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({
        productId: contract.productId,
        customer: contract.customer,
        price: contract.price,
        quantity: contract.quantity,
        manager: contract.manager,
        contractDate: contract.contractDate,
      }),
    });
    const data = await res.json();
    getContracts();
    showToast("success", "Contract added successfully!"); 
  };
  
  

  const handleEditContract = (contract) => {
    setSelectedContract({ ...contract });
    setIsModalOpen(true);
  };

  const handleRemoveContract = async (id) => {
    try {
      await fetch(`${base_URL}/contracts/${id}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
      const updatedContracts = contracts.filter(
        (contract) => contract._id !== id
      );
      setContracts(updatedContracts);
      showToast("error", "Contract deleted successfully!"); 
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalOpen(false);
      getContracts();
    }
  };

  return (
    <div className="min-h-screen ml-10 max-w-[100%] pt-20 px-4 sm:px-6 lg:px-8 mx-auto bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("contracts.contractTitle")}
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-black dark:text-white">
            {t("contracts.contractTableName")}
          </h2>
          <div className="flex-1 flex justify-center">
            <Search isDarkMode={isDarkMode} onSearch={handleSearch} />
          </div>
          <ContractFilter
            uniqueProducts={uniqueProducts}
            handleFilterByDate={handleFilterByDate}
            handleFilterByProductName={handleFilterByProductName}
          />

          <button
            onClick={() => {
              setIsModalOpen(true);
              setSelectedContract(null);
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-1 ml-4"
          >
            <PlusIcon className="w-5 h-5" />
            {t("contracts.contractAddBTN")}
          </button>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <ContractTable
            contracts={filteredContracts.length > 0 ? filteredContracts : contracts} 
            onEditContract={handleEditContract}
            onRemoveContract={handleRemoveContract}
          />
        )}
      </div>

      <ToastContainer />

      <ContractModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedContract={selectedContract}
        onSave={addContracts}
        onEdit={handleEditContract}
        isEditMode={selectedContract}
        handleRemoveContract={handleRemoveContract}
        onGetContracts={getContracts}
      />
    </div>
  );
}
