// import React, { useState } from "react";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons from react-icons
// import { useNavigate } from "react-router-dom";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate(); // Hook to navigate

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Email:", email);
//     console.log("Password:", password);

//     // Reset the form values
//     setEmail("");
//     setPassword("");

//     // Navigate to the home page
//     navigate("/"); // This will redirect to '/'
//   };

//   const handlePasswordToggle = () => {
//     setShowPassword((prev) => !prev);
//   };

//   return (
//     <>
//       {/* <div className="bg-[#012C3C] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#015876] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#0082B3] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#00ADEF] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#00ADEF] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#32BEF1] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#66CEF5] rounded-[12px] p-10 w-[70px]"></div>
//       <div className="bg-[#99DFF9] rounded-[12px] p-10 w-[70px]"></div> */}
//       <div className="fixed bg-gradient-to-br form-blue-600 to-white rounded-[20px] inset-0 z-50 flex items-center justify-center">
//         <div className="relative w-screen h-screen bg-gradient-to-br from-blue-800 via-blue-600 to-white overflow-hidden">
//           <div className="flex max-w-[1320px] mx-auto items-center justify-between p-4">
//             <div className="w-[300px] my-[200px] ml-[200px]">
//               <h2 className="text-4xl text-white font-bold mb-4 font-secondFamily">
//                 Welcome back...
//               </h2>
//               <form onSubmit={handleSubmit} className="space-y-4">
//                 <div>
//                   <label
//                     htmlFor="email"
//                     className="block text-white text-base font-medium mb-1"
//                   >
//                     Email:
//                   </label>
//                   <input
//                     type="email"
//                     id="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Enter your email"
//                     className="w-full p-2 border border-gray-300 rounded-md"
//                     required
//                   />
//                 </div>
//                 <div>
//                   <label
//                     htmlFor="password"
//                     className="block text-white text-base font-medium mb-1"
//                   >
//                     Password:
//                   </label>
//                   <div className="relative">
//                     <input
//                       type={showPassword ? "text" : "password"}
//                       id="password"
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                       placeholder="Enter your password"
//                       className="w-full p-2 border border-gray-300 rounded-md"
//                       required
//                     />
//                     <button
//                       type="button"
//                       onClick={handlePasswordToggle}
//                       className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
//                     >
//                       {showPassword ? <FaEyeSlash /> : <FaEye />}
//                     </button>
//                   </div>
//                 </div>

//                 <button
//                   type="submit"
//                   className="w-full bg-blue-500 text-white py-2 rounded-md mt-4 hover:bg-blue-400 transition-all duration-300"
//                 >
//                   Login
//                 </button>
//               </form>
//             </div>
//             <img src="/logo.png" alt="logo image" className="mr-[50px]" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <>
      <div className="fixed bg-gradient-to-br form-blue-600 to-white rounded-[20px] inset-0 z-50 flex items-center justify-center">
        <div className="relative w-screen h-screen overflow-hidden">
          <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg flex">
              <div className="w-1/2 p-20">
                <h2 className="text-2xl font-medium mb-6">Sign In</h2>
                <form onSubmit={handleSignIn}>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </div>
                  <div className="mb-4 relative">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-10 text-gray-500 focus:outline-none"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {/* <div className="flex items-center justify-end mb-6">
                    <a
                      href="#"
                      className="text-sm text-purple-500 hover:underline"
                    >
                      Forgot Password
                    </a>
                  </div> */}
                  <button
                    type="submit"
                    className="text-center w-full bg-[#36b7fe] text-white py-2 rounded-[7px] mt-7 hover:bg-[#2ca8e6] transition duration-300"
                  >
                    Sign in
                  </button>
                </form>
              </div>
              <div className="w-1/2 bg-gradient-to-tl from-white via-gray-100 to-gray-200 p-10 flex flex-col items-center justify-center rounded-r-xl shadow-lg">
                {/* <button
                  className="w-full py-3 bg-purple-900 text-white rounded-lg shadow-lg hover:bg-purple-800 transition mb-4"
                  onClick={() => {
                    console.log(formData);
                    navigate("/");
                  }}
                >
                  Sign me in now
                </button> */}
                <div>
                  <img className="mt-4" src="logo.png" alt="logo image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
