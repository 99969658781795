import { useState, useEffect } from "react";
import App from "./App";
import { Navbar, Sidebar } from "./ui";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./locale/translationsEN";
import translationsUZ from "./locale/translationsUZ";
import translationsRU from "./locale/translationsRU";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEN },
    uz: { translation: translationsUZ },
    ru: { translation: translationsRU },
  },
  lng: "uz",
  fallbackLng: "uz",
});

export default function Layout() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      const isDark = savedTheme === "dark";
      setIsDarkMode(isDark);
    }

    // Tilni yuklash
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  const changeLang = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
  };

  return (
    <div className={`flex ${isDarkMode ? "dark" : ""}`}>
      <Sidebar isDarkMode={isDarkMode} />
      <div className="flex-1 ml-64">
        <Navbar
          changeLang={changeLang}
          isDarkMode={isDarkMode}
          toggleTheme={toggleTheme}
        />
        <main className="p-6 bg-[#FAFAFB] dark:bg-gray-900 min-h-screen">
          <App />
        </main>
      </div>
    </div>
  );
}
