import { useEffect, useState } from "react";
import { ManagersModal, ManagerTable, SalesModal, Search } from "../components";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const BASE_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/api";
export default function Managers({ isDarkMode }) {
  const [managers, setManagers] = useState([]);
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const token = process.env.REACT_APP_ACCESS_TOKEN;


  useEffect(() => {
    const fetchManagers = async () => {
      setLoading(true); 
      try {
        const response = await fetch(`${BASE_URL}/managers`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, 
            "Content-Type": "application/json", 
          },
        });
        const data = await response.json();
        if (data.success) {
          setManagers(data.data);
        } else {
          toast.error("Failed to load managers.");
        }
      } catch (error) {
        console.error("Error fetching managers:", error);
        toast.error("Error fetching managers."); 
      } finally {
        setLoading(false); 
      }
    };
  
    fetchManagers();
  }, []);
  

  useEffect(() => {
    const filtered = managers.filter((manager) =>
      manager.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredManagers(filtered);
  }, [managers, searchQuery]);

  const handleSaveManager = (updatedManager) => {
    setManagers((prev) => {
      const index = prev.findIndex((m) => m._id === updatedManager._id);
      if (index >= 0) {
        const newManagers = [...prev];
        newManagers[index] = updatedManager;
        return newManagers;
      }
      return [...prev, updatedManager];
    });
    setIsManagerModalOpen(false);
  };

  const handleSalesEditClick = (manager) => {
    console.log("Selected manager:", manager);
    setSelectedManager(manager);
    setIsSalesModalOpen(true);
  };

  return (
    <div className="min-h-screen ml-10 min-w-full pt-20 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("managers.managerTitle")}
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-black dark:text-white">
            {t("managers.managerTableTitle")}
          </h2>
          <Search isDarkMode={isDarkMode} onSearch={setSearchQuery} />
          <button
            onClick={() => {
              setIsManagerModalOpen(true);
              setSelectedManager(null);
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-1"
          >
            <PlusIcon className="w-5 h-5" />
            {t("managers.managerAddBTN")}
          </button>
        </div>
        {loading ? (
          <Loader /> // Show loader when data is being fetched
        ) : filteredManagers && filteredManagers.length > 0 ? (
          <ManagerTable
            managers={filteredManagers}
            onEditManager={(manager) => {
              setSelectedManager(manager);
              setIsManagerModalOpen(true);
            }}
            onSalesEditClick={handleSalesEditClick}
          />
        ) : (
          <p className="text-gray-700 dark:text-gray-300">
            {t("managers.managerNoManager")}
          </p>
        )}
      </div>
      {isManagerModalOpen && (
        <ManagersModal
          isOpen={isManagerModalOpen}
          manager={selectedManager}
          isEditMode={Boolean(selectedManager)}
          onClose={() => setIsManagerModalOpen(false)}
          onSave={handleSaveManager}
        />
      )}
      {isSalesModalOpen && selectedManager && (
        <SalesModal
          manager={selectedManager}
          onClose={() => setIsSalesModalOpen(false)}
          onSave={handleSaveManager}
        />
      )}
    </div>
  );
}
